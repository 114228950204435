import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Cryptography } from 'mp-common-js';
import { withTheme, withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from '../../config/i18n';

import Header from '../../components/ecomm-integration-header/EcommHeader';
import CreditCardField from '../../components/credit-card/CreditCardField';
import PanHelp from '../credit-card-eligibility-validation/pan-help/PanHelp';
import PanErrors from './pan-errors/PanErrors';
import BottomNavigation from './bottom-navigation/BottomNavigation';
import EligibilityResult from './eligibility-result/EligibilityResult';

import { paymentCardEligibilityCheckAction } from '../../state/actions/EnrollmentActions';
import { iframeResponse } from '../../utils';

import styles from './styles';

import genericPaymentCard from '../credit-card-eligibility-validation/img/generic-bank-card.png';

export const EligibilityCheckPage = ({ classes, t, theme }) => {
  const [pan, setPan] = useState('');
  const [showWhatIsPan, setShowWhatIsPan] = useState(false);
  const [isMastercard, setIsMastercard] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [eligibilityResult, setEligibilityResult] = useState(null);

  let timer;

  const enrollment = useSelector((state) => state.enrollment);

  const dispatch = useDispatch();

  useEffect(() => {
    const publicKey = Cryptography.RSACrypto.getPublicKey();

    if (!publicKey) {
      setError('NO_PUBLIC_KEY');
      iframeResponse('ERROR', 'public key is missing');
    }
  }, []);

  useEffect(() => {
    return () => clearTimeout(timer);
  }, [timer]);

  const checkIfMastercard = (type) =>
    type === 'Mastercard' || type === 'Maestro';

  function resetCheck() {
    setPan('');
    setEligibilityResult(null);
    setIsValid(false);
    setError(null);
    setErrorMessage(null);
    setIsMastercard(false);
  }

  function handleOnChange(event) {
    setError(null);
    setIsMastercard(checkIfMastercard(event.type));
    setIsValid(event.valid);
    setPan(event.value);
  }

  function handleWhatIsPan() {
    if (!showWhatIsPan) {
      setShowWhatIsPan(true);
      timer = setTimeout(() => {
        setShowWhatIsPan(false);
      }, 10000);
    }
  }

  function handleGetPan() {
    const bankSupportLink =
      'https://www.abnamro.nl/portalserver/mijn-abnamro/betalen/wearables/index.html';
    iframeResponse('NOPAN', bankSupportLink);
  }

  function checkPan() {
    if (!isValid) {
      setError('PAN_NOT_VALID');
      return null;
    }

    if (!isMastercard) {
      setError('PAN_NOT_MASTERCARD');
      return null;
    }

    return dispatch(
      paymentCardEligibilityCheckAction(
        pan,
        () => {
          iframeResponse('PAN_ELIGIBLE');
          setEligibilityResult('ELIGIBLE');
        },
        (err) => {
          if (err?.includes('1016') || err?.includes('2004')) {
            setEligibilityResult('NOT_ELIGIBLE');
            iframeResponse('PAN_NOT_ELIGIBLE');
          } else {
            setError('API_REQUEST_ERROR');
            setErrorMessage(err);
          }
        },
      ),
    );
  }

  if (error === 'NO_PUBLIC_KEY')
    return (
      <div className={classes.mainContainer}>
        <div className={classes.publicKeyErrorContainer}>
          <Typography color="error" variant="h5">
            {t('reg.notAuthorised')}
          </Typography>
        </div>
      </div>
    );

  return (
    <div className={classes.mainContainer}>
      <Header />
      <Typography color="textPrimary" variant="h5" gutterBottom>
        {t('cardEligibilityRoute.header')}
      </Typography>
      <div className={classes.bodyContainer}>
        {!eligibilityResult && (
          <>
            <div className={classes.panAndImageContainer}>
              <div className={classes.creditCardFieldContainer}>
                <CreditCardField
                  data-test="creditcardfield"
                  accountNumber={pan}
                  fullWidth
                  onPanSubmitted={() => checkPan()}
                  onChange={(e) => {
                    handleOnChange(e);
                  }}
                  InputLabelProps={{ shrink: true, hidden: true }}
                  endAdornment={
                    <InputAdornment position="end">
                      {enrollment.isFetching && <CircularProgress size={18} />}
                    </InputAdornment>
                  }
                />
                {error && (
                  <PanErrors
                    error={error}
                    errorMessage={errorMessage}
                    pan={pan}
                    t={t}
                  />
                )}
                <div>
                  <Typography
                    data-test="what-is-pan"
                    variant="subtitle2"
                    className={classes.link}
                    color="primary"
                    onClick={() => handleWhatIsPan()}
                    gutterBottom
                  >
                    {t('noPanFlow.whatCardNumber')}
                  </Typography>
                </div>
              </div>
              <div className={classes.creditCardImage}>
                <img
                  data-test="card-image"
                  src={genericPaymentCard}
                  alt="generic-payment-card"
                  className={classes.genericPaymentCard}
                />
                <div className={classes.cardImageTextInfo}>
                  <Typography
                    data-test="card-image-text-info"
                    variant="body2"
                    gutterBottom
                  >
                    {t('components.creditCard.cardImageTextInfo')}
                  </Typography>
                </div>
              </div>
            </div>
            <div>
              <PanHelp
                data-test="panhelp"
                showWhatIsPan={showWhatIsPan}
                theme={theme}
                t={t}
                onGetPan={handleGetPan}
              />
            </div>
          </>
        )}

        {eligibilityResult === 'ELIGIBLE' && (
          <EligibilityResult isEligible t={t} />
        )}
        {eligibilityResult === 'NOT_ELIGIBLE' && (
          <EligibilityResult isEligible={false} t={t} />
        )}
      </div>
      <div className={classes.bottomNavigationContainer}>
        {!eligibilityResult ? (
          <BottomNavigation
            leftAction={() => iframeResponse('USER_HAS_CANCELLED')}
            leftLabel={t('button.back')}
            rightAction={() => checkPan()}
            rightLabel={t('button.checkEligibility')}
          />
        ) : (
          <BottomNavigation
            leftAction={() => resetCheck()}
            leftLabel={t('button.checkAnotherCard')}
            rightAction={() => iframeResponse('ELIGIBILITY_FINISHED')}
            rightLabel={t('button.finish')}
          />
        )}
      </div>
    </div>
  );
};

EligibilityCheckPage.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  t: PropTypes.func.isRequired,
  theme: PropTypes.shape({}).isRequired,
};

export default withTheme(
  withTranslation(withStyles(styles)(EligibilityCheckPage)),
);
