const styles = () => ({
  mainContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  successIcon: { fontSize: 80, color: 'green', marginBottom: 40 },
  failIcon: { fontSize: 80, color: 'red', marginBottom: 40 },
  messageContainer: { minWidth: '300px', maxWidth: '80%', textAlign: 'center' },
});

export default styles;
