import React from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import { withTranslation } from '../../config/i18n';

import IframeFooter from '../../components/iframe-footer/IframeFooter';
import Logo from '../../components/logo';
import manageMiiLogo from './img/manageMiiLogo.svg';

import styles from './styles';

export const LoadingPage = ({ classes, t, routeType }) => (
  <div className={classes.mainContainer}>
    <Typography
      variant="h6"
      gutterBottom
      className={classes.headerText}
      data-test="HeaderText"
    >
      {routeType === 'eligibility-check'
        ? t('loadingPage.headerTextEligibilityRoute')
        : t('loadingPage.headerText')}
    </Typography>
    <div>
      <Logo height={110} width={300} />
    </div>
    <div className={classes.managedByContainer}>
      <Typography>{t('loadingPage.managedBy')} </Typography>
      <img
        className={classes.manageByImg}
        src={manageMiiLogo}
        alt="manage-mii-logo"
      />
      <Typography>{t('loadingPage.platformName')}</Typography>
    </div>

    <CircularProgress />
    <IframeFooter showCreditsImages />
  </div>
);

LoadingPage.prototype = {
  classes: PropTypes.shape({}).isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation(withStyles(styles)(LoadingPage));
