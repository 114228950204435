import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Typography from '@material-ui/core/Typography';

import styles from './styles';

const EligibilityResult = ({ isEligible, t, classes }) => {
  return (
    <div className={classes.mainContainer}>
      {isEligible ? (
        <CheckCircleOutlineIcon className={classes.successIcon} />
      ) : (
        <HighlightOffIcon className={classes.failIcon} />
      )}
      <div className={classes.messageContainer}>
        <Typography
          data-test="card-image-text-info"
          variant="body2"
          gutterBottom
        >
          {isEligible
            ? t('cardEligibilityRoute.notEligible')
            : t('components.creditCard.cardNumberNotEligible')}
        </Typography>
      </div>
    </div>
  );
};

EligibilityResult.propTypes = {
  isEligible: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

export default withStyles(styles)(EligibilityResult);
