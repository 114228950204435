import React from 'react';
import PropTypes from 'prop-types';
import StyledButon from '../../../components/styled-button/StyledButon';

const BottomNavigation = ({
  leftLabel,
  leftAction,
  rightLabel,
  rightAction,
}) => {
  return (
    <>
      <StyledButon onClick={leftAction}>{leftLabel}</StyledButon>
      <StyledButon onClick={rightAction}>{rightLabel}</StyledButon>
    </>
  );
};

BottomNavigation.propTypes = {
  leftLabel: PropTypes.string.isRequired,
  leftAction: PropTypes.func.isRequired,
  rightLabel: PropTypes.string.isRequired,
  rightAction: PropTypes.func.isRequired,
};

export default BottomNavigation;
