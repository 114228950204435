const styles = (theme) => ({
  mainContainer: {
    backgroundColor: theme.palette.backgroundColor,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '600px',
    width: '100%',
    maxWidth: '800px',
    margin: 'auto',
  },
  bodyContainer: { maxWidth: '600px' },
  panAndImageContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  creditCardFieldContainer: {
    width: '300px',
    margin: '0 20px',
  },
  creditCardImage: {
    maxWidth: '250px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '@media (max-width: 425px)': {
      display: 'none',
    },
  },
  cardImageTextInfo: {
    color: theme.palette.text.primary,
    textAlign: 'center',
  },
  genericPaymentCard: {
    maxHeight: '188px',
  },
  link: {
    color: theme.palette.primary.main,
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
  bottomNavigationContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-around',
    alignItems: 'center',
    marginBottom: '50px',
  },
  publicKeyErrorContainer: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default styles;
