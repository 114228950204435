/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { connect } from 'react-redux';
import i18next from 'i18next';
import {
  set_configuration as setConfiguration,
  set_environment as setEnvironment,
} from 'mp-common-js';
import { withStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import { withTranslation } from '../config/i18n';
import { ENV } from '../env';

import { validateDeviceAction } from '../state/actions/SecurityActions';
import processQueryParams from '../state/actions/iFrameActions';

import { buildDeviceFromParams, iframeResponse } from '../utils';

import styles from './styles';

class Iframe extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      configurationError: undefined,
    };
  }

  async componentDidMount() {
    const { security } = this.props;
    const { entityId, isDeviceValid } = security;

    setEnvironment(ENV);

    if (!entityId) {
      if (!isDeviceValid) {
        await this.handleValidateDevice();
      }
      this.handleSetConfiguration();
    }
  }

  async handleValidateDevice() {
    const { onValidateDevice, params, onProcessQueryParams } = this.props;

    onProcessQueryParams(params);
    if (params.lang) i18next.changeLanguage(params.lang);

    try {
      await onValidateDevice(buildDeviceFromParams(params));
    } catch (error) {
      this.handleErrorResponse(error);
    }
  }

  async handleSetConfiguration() {
    const { params, discovery } = this.props;
    if (discovery.entity?.id)
      try {
        await setConfiguration({
          clientId: params.clientId,
          entityId: discovery.entity.id,
          type: 'web',
          environment: ENV,
        });

        iframeResponse('IFRAME_LOADED');
      } catch (error) {
        this.handleErrorResponse(error);
      }
  }

  handleErrorResponse(err) {
    const { t } = this.props;
    if (err.response?.data?.message) {
      switch (err.response.data.message) {
        case 'Client not found':
          this.setState({
            configurationError: t('reg.noClientId'),
          });
          iframeResponse('ERROR', err.response.data.message);
          break;
        case 'Network Error':
          this.setState({
            configurationError: t('reg.networkError'),
          });
          iframeResponse('ERROR', err.response.data.message);
          break;
        default:
          this.setState({
            configurationError: err.response.message,
          });
          iframeResponse('ERROR', err.response.data.message);
      }
    } else {
      this.setState({
        configurationError: t('reg.networkError'),
      });
      iframeResponse('ERROR', t('reg.networkError'));
    }
  }

  render() {
    const {
      component: Component,
      classes,
      props,
      security,
      t,
      routeType,
    } = this.props;

    const { isProcessing, errorValidatingDevice } = security;

    const { configurationError } = this.state;

    if (isProcessing)
      return (
        <div className={classes.loading}>
          <CircularProgress
            className={classes.loadingCircularProgress}
            size={60}
            thickness={5}
          />
        </div>
      );

    if (configurationError) {
      return (
        <div className={classes.iframe}>
          <h1>{configurationError}</h1>
        </div>
      );
    }

    if (errorValidatingDevice) {
      return (
        <div className={classes.iframe}>
          <h1>{t('reg.deviceNotValid')}</h1>
        </div>
      );
    }

    return (
      <div className={classes.iframe}>
        <Component {...props} routeType={routeType} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  discovery: state.discovery,
  security: state.security,
  enrollment: state.enrollment,
});

const mapDispatchToProps = (dispatch) => ({
  onProcessQueryParams: (params) => dispatch(processQueryParams(params)),
  onValidateDevice: (device) => dispatch(validateDeviceAction(device)),
});

export default withTranslation(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Iframe)),
);
