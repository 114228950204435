import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

const PanErrors = ({ error, pan, errorMessage, t, classes }) => {
  return (
    <div className={classes.mainContainer}>
      <>
        {error === 'PAN_NOT_VALID' && pan !== '' && (
          <span>{t('components.creditCard.cardNumberNotValid')}</span>
        )}
        {pan === '' && <span>Please enter a pan number</span>}
        {pan !== '' && error === 'PAN_NOT_MASTERCARD' && (
          <span>{t('components.creditCard.cardNumberNotEligible')}</span>
        )}
        {error === 'API_REQUEST_ERROR' && pan !== '' && (
          <span>{errorMessage}</span>
        )}
      </>
    </div>
  );
};

PanErrors.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  error: PropTypes.string,
  t: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
};

PanErrors.defaultProps = {
  error: null,
  errorMessage: null,
};

export default withStyles(styles)(PanErrors);
